<template>
  <v-container>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md6>
        <v-card class="overflow-hidden">
          <v-form @submit.prevent="submit" lazy-validation>
            <v-toolbar color="primary" dark>
              <v-toolbar-title>{{ title }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <div v-if="response">{{ $t(response) }}</div>
              <div v-else-if="withToken">
                Inserisci una nuova password per l'account {{ username }}
                <v-text-field
                  :error-messages="errorMessages.password"
                  label="Password"
                  v-model="password"
                  type="password"
                  id="password"
                  autocomplete="new-password"
                />
                <v-text-field
                  :error-messages="errorMessages.password_confirmation"
                  label="Password confirmation"
                  v-model="password_confirmation"
                  type="password"
                  id="password_confirmation"
                  autocomplete="new-password"
                />
              </div>
              <div v-else>
                Inserisci il nome utente per cui richiedi il reset della password
                <v-text-field
                  :error-messages="errorMessages.username_request"
                  label="Username"
                  v-model="username_request"
                  required
                />
              </div>
              <v-alert border="left" outlined color="error" v-if="error">
                <div>{{ $t(`errors.${error.message}`) }}</div>
              </v-alert>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn :disabled="$v.$invalid" v-if="!response" type="submit" color="primary">{{ actionText }}</v-btn>
              <v-btn to="/login" v-else type="submit" color="primary">Torna a Login</v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { ME } from "@/graphql/queries";
import { FORGOT_PASSWORD, RESET_PASSWORD } from "../graphql/mutations";
import { requiredIf, sameAs, minLength } from "vuelidate/lib/validators";

export default {
  name: "Login",
  data: () => ({
    password: null,
    password_confirmation: null,
    username_request: null,
    response: null,
    error: null,
  }),
  props: {
    username: {
      type: String,
    },
    token: {
      type: String,
    },
  },
  validations() {
    return {
      username_request: {
        required: requiredIf(() => !this.withToken),
      },
      password: {
        required: requiredIf(() => this.withToken),
        minLength: minLength(8),
      },
      password_confirmation: {
        required: requiredIf(() => this.withToken),
        sameAsPassword: sameAs("password"),
      },
    };
  },
  computed: {
    title() {
      return this.withToken ? "Reset password" : "Forgot password";
    },
    actionText() {
      return this.withToken ? "Resetta" : "Richiedi";
    },
    withToken() {
      return this.username && this.token;
    },

    errorMessages() {
      const errors = {};
      if (!this.$v.password.$pending && !this.$v.password.required) {
        errors.password = "Password is required";
      } else if (!this.$v.password.$pending && !this.$v.password.minLength) {
        errors.password = `Password must be at least ${this.$v.password.$params.minLength.min} characters`;
      }

      if (!this.$v.password_confirmation.$pending && !this.$v.password_confirmation.sameAsPassword) {
        errors.password_confirmation = "Passwords must match";
      }

      if (!this.$v.username_request.$pending && !this.$v.username_request.required) {
        errors.username_request = "Username is required";
      }

      return errors;
    },
  },
  apollo: {
    me: ME,
  },
  methods: {
    submit() {
      this.error = null;
      this.response = null;
      const mutation = this.withToken ? RESET_PASSWORD : FORGOT_PASSWORD;
      const variables = this.withToken
        ? { input: { username: this.username, token: this.token, password: this.password } }
        : { username: this.username_request };

      this.$apollo
        .mutate({ mutation, variables })
        .then(({ data }) => {
          this.response = data.resetPassword || data.forgotPassword;
          this.password = null;
          this.password_confirmation = null;
          this.username_request = null;
        })
        .catch((error) => (this.error = this.getGraphQLError(error)));
    },
    getGraphQLError(error) {
      const graphQLError = error.graphQLErrors[0];
      if (graphQLError && graphQLError.extensions && graphQLError.extensions.validation) {
        return {
          type: "validation",
          message: Object.values(graphQLError.extensions.validation)[0][0],
        };
      }
      return {
        type: "general",
        message: graphQLError ? graphQLError.message : "An unknown error occurred",
      };
    },
  },
};
</script>
